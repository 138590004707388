import './Faq.css';
import React from 'react';

import FaqQuestionsList from './FaqQuestionsList';

const Faq = function Faq({ questions }) {
  return (
    questions.length > 0 && (
    <section className="faq">
      <h2 className="faq__title">FAQ</h2>
      <FaqQuestionsList
        questions={questions}
      />
    </section>
    )
  );
};

export default Faq;
