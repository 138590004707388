import './ResetPassword.css';

import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import Button from './Button';
import Form from './Form';
import InputEmail from './InputEmail';

const ForgotPassword = function ForgotPassword({
  registerLink,
  loginLink,
  error,
  onResetStepEmail,
  forgotStep,
}) {
  const [email, setEmail] = useState('');

  const handleStepEmailSubmit = (e) => {
    e.preventDefault();

    if (email === '') {
      return;
    }

    onResetStepEmail({
      email,
    });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <>

      {forgotStep === 1
            && (
            <>
              <Form onSubmit={handleStepEmailSubmit}>
                <InputEmail
                  title="Введите email:"
                  value={email}
                  onChange={handleEmailChange}
                  error={error}
                  description="На эту почту вы получите ссылку для восстановления пароля"
                />
                <Button name="Восстановить пароль" />
              </Form>
              <div className="reset-pass">
                <Link
                  to={registerLink}
                  className="reset-pass__link-signup"
                >
                  Регистрация
                </Link>
                <Link
                  to={loginLink}
                  className="reset-pass__link-signup"
                >
                  Вход
                </Link>
              </div>
            </>
            )}
      {forgotStep === 2
      && (
        <>
          <div>
            На вашу почту (
            {email}
            ) отправлено письмо с ссылкой для восстановления пароля.
            Перейдите по ней, чтобы восстановить пароль
          </div>

          <div className="reset-pass">
            <Link
              to={registerLink}
              className="reset-pass__link-signup"
            >
              Регистрация
            </Link>
            <Link
              to={loginLink}
              className="reset-pass__link-signup"
            >
              Вход
            </Link>
          </div>
        </>
      )}
    </>
  );
};

export default ForgotPassword;
