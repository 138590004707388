import './Footer.css';

import React from 'react';

import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';

const Footer = function Footer({ messengers }) {
  return (
    <div className="footer">
      <p className="footer__title">Контакты для связи:</p>
      <nav className="footer__nav">
        <ul className="footer__items">
          {messengers && messengers.map((object) => (
            <li
              className="footer__item"
              key={`${object.name}${object.link}`}
            >
              <Link
                to={{ pathname: object.link }}
                target="_blank"
                className="footer__link"
              >
                <SVG
                  src={object.image}
                  className="footer__social-icon"
                />
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Footer;
