import React from 'react';

import './Fieldset.css';

const Fieldset = function Fieldset({ children }) {
  return (
    <fieldset className="fieldset">
      {children}
    </fieldset>
  );
};

export default Fieldset;
