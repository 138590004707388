import './Logo.css';
import React from 'react';

import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';

const Logo = function Logo({
  link, image, loggedIn, location,
}) {
  return (
    loggedIn && link !== location.pathname
      ? (
        <Link to={link}>
          <SVG
            src={image}
            className="logo"
            uniquifyIDs
          />
        </Link>
      )
      : (
        <SVG
          src={image}
          className="logo"
          uniquifyIDs
        />
      )

  );
};

export default Logo;
