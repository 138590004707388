import 'react-phone-input-2/lib/style.css';
import './Input.css';
import './InputPhone.css';
import React from 'react';

import PhoneInput from 'react-phone-input-2';
import ru from 'react-phone-input-2/lang/ru.json';

const InputPhone = function InputPhone({
  phone,
  onChange,
  error,
  description = '',
}) {
  return (
    <div className="input-box">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="inputPhoneId" className="input-box__title">
        Введите номер телефона:
        <PhoneInput
          id="inputPhoneId"
          placeholder="+380 905 201 23 53"
          country="ru"
          // onlyCountries={['ru', 'ua']}
          preferredCountries={['ru', 'ua', 'by', 'kz']}
          preserveOrder={['onlyCountries', 'preferredCountries']}
          // regions={['ex-ussr']}
          localization={ru}
          value={phone}
          onChange={onChange}
          inputStyle={
                        error.phone
                        && {
                          border: '1px solid #F0392F',
                        }
                    }
          containerClass="input-phone__containerClass"
        />
      </label>
      {description
            && (
            <span className="input-box__input-description">
              {description}
            </span>
            )}
      {error.phone
            && (
            <span
              className="input-box__error"
            >
              {error.phone}
            </span>
            )}
    </div>
  );
};

export default InputPhone;
