import './ResponsivePlayer.css';
import React from 'react';

import ReactPlayer from 'react-player';

import backgroundPlayer from '../images/backgroundPlayer.png';

const ResponsivePlayer = function ResponsivePlayer({ url }) {
  return (
    <div className="responsive-player">
      <ReactPlayer
        onContextMenu={(e) => e.preventDefault()}
        url={url}
        className="responsive-player__player"
        width="100%"
        height="100%"
        controls
        playing
        playIcon={<div className="responsive-player__player-play" />}
        light={backgroundPlayer}
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload',
            },
          },
        }}
      />
    </div>
  );
};

export default ResponsivePlayer;
