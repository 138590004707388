import './FaqQuestionsCard.css';
import React, { useState } from 'react';

const FaqQuestionsCard = function FaqQuestionsCard({
  question,
  answer,
}) {
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  return (
    <li className="faq-questions-card">
      <button
        type="button"
        className={`faq-questions-card__title ${isVisible
          && 'faq-questions-card__title-visible'} `}
        onClick={handleClick}
      >
        {question}
      </button>
      <div
        className={`faq-questions-card__answer ${isVisible
        && 'faq-questions-card__answer-visible'} `}
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{ __html: answer }}
      />
    </li>
  );
};

export default FaqQuestionsCard;
