import './Login.css';

import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import Button from './Button';
import Fieldset from './Fieldset';
import Form from './Form';
import InputCode from './InputCode';
import InputEmail from './InputEmail';
import InputPassword from './InputPassword';
import InputPhone from './InputPhone';

const Login = function Login({
  registerLink,
  error,
  onLoginStepPhone,
  onLoginStepPhoneCode,
  onLoginStepEmail,
  loginStep,
  resetLink,
}) {
  const [phone, setPhone] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleStepPhoneSubmit = (e) => {
    e.preventDefault();

    if (phone === '') {
      return;
    }

    onLoginStepPhone({
      phone,
    });
  };

  const handleStepPhoneCodeSubmit = (e) => {
    e.preventDefault();

    onLoginStepPhoneCode({
      phoneCode,
    });
  };

  const handleStepEmailSubmit = (e) => {
    e.preventDefault();

    if (email === '' || password === '') {
      return;
    }

    onLoginStepEmail({
      email, password,
    });
  };

  const handleRepeatCodeSubmit = () => {
    onLoginStepPhone({
      phone,
    });
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  const handlePhoneCodeChange = (e) => {
    setPhoneCode(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <>

      {loginStep === 1
            && (
            <>
              <Form onSubmit={handleStepEmailSubmit}>
                <Fieldset>
                  <InputEmail
                    title="Введите email:"
                    value={email}
                    onChange={handleEmailChange}
                    error={error}
                    description="На эту почту вы получите код подтверждения"
                  />
                  <InputPassword
                    title="Введите пароль:"
                    value={password}
                    onChange={handlePasswordChange}
                    error={error}
                    description=""
                  />
                </Fieldset>
                <Link
                  to={resetLink}
                  className="login__link-reset"
                >
                  Забыли пароль?
                </Link>
                <Button name="Войти" />
              </Form>
              <Link
                to={registerLink}
                className="login__link-signup"
              >
                Регистрация
              </Link>
            </>
            )}
      {loginStep === 2
      && (
        <>
          <Form onSubmit={handleStepPhoneSubmit}>
            <InputPhone
              phone={phone}
              onChange={handlePhoneChange}
              error={error}
              description="На этот номер вы получите sms с кодом подтверждения"
            />
            <Button name="Получить код" />
          </Form>
          <Link
            to={registerLink}
            className="login__link-signup"
          >
            Регистрация
          </Link>
        </>
      )}
      {loginStep === 3
            && (
              <>
                <Form onSubmit={handleStepPhoneCodeSubmit}>
                  <InputCode
                    onChange={handlePhoneCodeChange}
                    onRepeatCode={handleRepeatCodeSubmit}
                    value={phoneCode}
                    error={error}
                  />
                  <Button name="Подтвердить" />
                </Form>
                <Link
                  to={registerLink}
                  className="login__link-signup"
                >
                  Регистрация
                </Link>
              </>
            )}
    </>
  );
};

export default Login;
