import React from 'react';

import './vendor/normalize.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import WebFont from 'webfontloader';

import App from './components/App';

WebFont.load({
  google: {
    families: ['Nunito:300,400,600,700', 'sans-serif'],
  },
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
