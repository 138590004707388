import './Input.css';
import './InputCode.css';
import React from 'react';

const InputEmail = function InputEmail({
  title,
  value,
  onChange,
  error,
}) {
  return (
    <div className="input-box">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="inputEmailId" className="input-box__title">
        {title}
        <input
          id="inputEmailId"
          type="email"
          className={`input-box__input ${error && error.email
            ? 'input-box__input-error' : ''}`}
          placeholder=""
          onChange={onChange}
          value={value}
        />
      </label>
      {error.email
      && (
        <span className="input-box__error">
          {error.email}
        </span>
      )}
    </div>
  );
};

export default InputEmail;
