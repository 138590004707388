import './ResetPassword.css';

import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import Button from './Button';
import Form from './Form';
import InputPassword from './InputPassword';

const ResetPassword = function ResetPassword({
  registerLink,
  loginLink,
  error,
  onResetStepPassword,
  resetStep,
}) {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [errorMessages, setErrorMessages] = useState('');

  function useQuery() {
    return new URLSearchParams(window.location.search);
  }

  const token = useQuery().get('token');
  const email = useQuery().get('email');

  const handleStepPasswordSubmit = (e) => {
    e.preventDefault();

    if (password !== passwordConfirm) {
      setErrorMessages('Пароли не совпадают');

      return;
    }

    if (token === '' || email === '' || password === '' || passwordConfirm === '') {
      setErrorMessages('Заполните все поля');

      return;
    }

    onResetStepPassword({
      token, email, password, passwordConfirm,
    });
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordConfirmChange = (e) => {
    setPasswordConfirm(e.target.value);
  };

  return (
    <>

      {resetStep === 1
            && (
              <>
                <Form onSubmit={handleStepPasswordSubmit}>
                  <input
                    type="hidden"
                    value={email}
                  />
                  <input
                    type="hidden"
                    value={token}
                  />
                  <InputPassword
                    title="Введите новый пароль:"
                    value={password}
                    onChange={handlePasswordChange}
                    error={error}
                    description=""
                  />
                  <InputPassword
                    title="Повторите пароль:"
                    value={passwordConfirm}
                    onChange={handlePasswordConfirmChange}
                    error={error}
                    errorMessages={errorMessages}
                    description=""
                  />
                  <Button name="Сохранить пароль" />
                </Form>
                <div className="reset-pass">
                  <Link
                    to={registerLink}
                    className="reset-pass__link-signup"
                  >
                    Регистрация
                  </Link>
                  <Link
                    to={loginLink}
                    className="reset-pass__link-signup"
                  >
                    Вход
                  </Link>
                </div>
              </>
            )}
      {resetStep === 2
      && (
        <>
          <div>
            Пароль успешно изменен. Теперь вы можете Войти в свой аккаунт
          </div>
          <div className="reset-pass">
            <Link
              to={registerLink}
              className="reset-pass__link-signup"
            >
              Регистрация
            </Link>
            <Link
              to={loginLink}
              className="reset-pass__link-signup"
            >
              Вход
            </Link>
          </div>
        </>
      )}
    </>
  );
};

export default ResetPassword;
