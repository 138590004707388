import './Task.css';

import React, { useEffect, useState } from 'react';

import Faq from './Faq';
import ResponsivePlayer from './ResponsivePlayer';
import Review from './Review';
import SearchForm from './SearchForm';

// TODO onClearMessages, функционал вывода ошибок в строке поиска (не используется)
const Task = function Task({
  task,
  onAddReview,
  onChangeTaskStatus,
  questions,
  ratings,
  error,
  onSearch,
  // onLogout,
  // onClearMessages,
}) {
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (task.status && task.status.code === 'default') {
      onChangeTaskStatus({ status: 'watch_later' });
    }
  }, [task, onChangeTaskStatus]);

  useEffect(() => {
    if (error.query) {
      // setSearchQuery('');
    }
  }, [error.query]);

  const handleReviewSubmit = (data) => {
    onAddReview({
      rating: data.rating,
      message: data.body,
    });
  };

  const handleChangeTaskStatusSubmit = (e) => {
    e.preventDefault();

    onChangeTaskStatus({ status: 'viewed' });
  };

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
    // onClearMessages();
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    if (!searchQuery) {
      return;
    }

    onSearch({
      searchQuery,
      inTask: true,
    });
  };

  return (
    <main className="task">
      <section className="task__search-form">
        <SearchForm
          placeholder="Введите шифр задания чтобы его найти"
          onSubmit={handleSearchSubmit}
          onChange={handleSearchQueryChange}
          value={searchQuery}
          error={error}
          inTask
        />
      </section>
      <section className="task__box">
        <h1 className="task__title">{task.title}</h1>
        <ResponsivePlayer
          url={task.video}
        />
        {task.status && (
          task.status.code !== 'viewed' ? (
            <form
              className="task__form-complete"
              onSubmit={handleChangeTaskStatusSubmit}
            >
              <button type="submit" className="task__button_complete">
                Отметить задание как выполненное
              </button>
            </form>
          )
            : <div className="task__done-text">Задание выполнено</div>
        )}
        {error
        && (
          <span
            className="search-form__input-error"
          >
            {error.taskDone}
          </span>
        )}
        {(!task.comment) && (
          <Review
            onSubmit={handleReviewSubmit}
            commentSent={task.commentSent}
            ratings={ratings}
            error={error}
          />
        )}
        <Faq questions={questions} />
        {/*
        <button
          type="submit"
          onClick={onLogout}
          style={{ cursor: 'pointer', margin: '30px' }}
        >
          Выход
        </button>
        */}
      </section>
    </main>
  );
};

export default Task;
