import './SearchForm.css';

import React from 'react';

const SearchForm = function SearchForm({
  onChange,
  onSubmit,
  error,
  value,
  inTask = false,
}) {
  return (
    <section className="search-form">
      <form
        action="#"
        className="search-form__form"
        onSubmit={onSubmit}
      >
        <input
          type="text"
          // placeholder={` ${error.query ? error.query
              /*: 'Введите шифр-слово, чтобы ознакомиться с заданием'}`} */
          placeholder="Введите шифр-слово, чтобы ознакомиться с заданием"
          onChange={onChange}
          value={value}
          className={`search-form__input-text
            ${inTask && 'search-form__input-text-task'}
          `}
        />
        {/* ${error.query && 'search-form__input-text-error'} */}
        <button
          type="submit"
          className="search-form__button-submit"
        >
          Найти
        </button>
        error
      </form>
      <span className="search-form__input-error">
        {error.query}
      </span>
    </section>
  );
};

export default SearchForm;
