import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import React, { useEffect, useState } from 'react';

import { Nav } from 'react-bootstrap';
import {
  Route, Switch, useHistory, useLocation,
} from 'react-router-dom';

import {
  ERROR_MESSAGES,
  SEARCH_LINK,
  SING_IN_LINK,
  SING_UP_LINK,
  TASK_LINK,
  RESET_PASS_LINK,
  FORGOT_PASS_LINK,
} from '../utils/config';
import Auth from './Auth';
import Error404 from './Error404';
import Footer from './Footer';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import Logo from './Logo';
import ProtectedRoute from './ProtectedRoute';
import Register from './Register';
import ResetPassword from './ResetPassword';
import Search from './Search';
import Task from './Task';

const axios = require('axios').default;

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

const App = function App() {
  const [task, setTask] = useState({});
  const [query, setQuery] = useState(
    localStorage.getItem('query')
      ? JSON.parse(localStorage.getItem('query')) : '',
  );
  const [questions, setQuestions] = useState([]);
  const [settings, setSettings] = useState({
    site_name: '',
    logo: '',
    video_start: '',
    messengers: [],
  });
  const [ratings, setRatings] = useState([]);
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem('loggedIn')
      ? JSON.parse(localStorage.getItem('loggedIn')) : false,
  );
  const [apiErrorMessage, setApiErrorMessage] = useState({});
  const [registerStep, setRegisterStep] = useState(1);
  const [loginStep, setLoginStep] = useState(1);
  const [resetStep, setResetStep] = useState(1);
  const [forgotStep, setForgotStep] = useState(1);
  const [locationUrl, setLocationUrl] = useState('');

  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    setLocationUrl(location.pathname);
  }, [location]);

  useEffect(() => {
    axios({
      method: 'get',
      url: '/user/auth',
    }).then((response) => {
      if (response.data.data.auth) {
        setLoggedIn(true);
        history.push(SEARCH_LINK);
        // history.push(TASK_LINK);
      } else {
        setLoggedIn(false);
      }
    }).catch(() => {
      setLoggedIn(false);
    });
  }, [history]);

  useEffect(() => {
    axios({
      method: 'get',
      url: '/questions',
    }).then((response) => {
      if (response.status === 200) {
        setQuestions(response.data.data);
      }
    }).catch(() => {
      // console.log(error.response)
    });

    setRatings([
      {
        id: 1,
        value: 'Звезда 1',
      },
      {
        id: 2,
        value: 'Звезда 2',
      },
      {
        id: 3,
        value: 'Звезда 3',
      },
      {
        id: 4,
        value: 'Звезда 4',
      },
      {
        id: 5,
        value: 'Звезда 5',
      },
    ]);

    if (query !== '') {
      axios({
        method: 'post',
        url: '/task/search',
        data: {
          cipher: query,
        },
      }).then((response) => {
        if (response.status === 200) {
          setTask({
            id: response.data.id,
            title: response.data.title,
            video: response.data.video,
            status: response.data.status,
            comment: response.data.comment,
            preview_text: response.data.preview_text,
          });
        }
      }).catch(() => {
        // console.log(error.response)
      });
    }

    axios({
      method: 'get',
      url: '/settings',
    }).then((response) => {
      if (response.status === 200) {
        try {
          setSettings(
            response.data.data,
          );
          document.title = response.data.data.site_name;
        } catch (e) {
          // console.log(e)
        }
      }
    }).catch(() => {
      // console.log(error.response)
    });
  }, [query]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  function addReview({ rating, message }) {
    axios({
      method: 'post',
      url: '/comments',
      data: {
        body: message,
        rating,
        task_id: task.id,
      },
    }).then((response) => {
      if (response.status === 200) {
        setTask({
          ...task,
          commentSent: true,
        });
      }
    }).catch((error) => {
      if (error.response.data.errors) {
        setApiErrorMessage({
          body: error.response.data.errors.body ? error.response.data.errors.body[0] : '',
          rating: error.response.data.errors.rating ? error.response.data.errors.rating[0] : '',
          task_id: error.response.data.errors.task_id ? error.response.data.errors.task_id[0] : '',
        });
      } else if (error.response.data && error.response.data.message) {
        setApiErrorMessage({
          body: error.response.data.message ? error.response.data.message : '',
        });
      } else {
        setApiErrorMessage({
          body: ERROR_MESSAGES.API,
        });
      }
    });
  }

  function onChangeTaskStatus({ status }) {
    axios({
      method: 'post',
      url: '/task/status',
      data: {
        task_id: task.id,
        status,
      },
    }).then((response) => {
      if (response.status === 200) {
        setTask({
          ...task,
          status: {
            code: status,
          },
        });
      }
    }).catch((error) => {
      try {
        setApiErrorMessage({
          phone: error.response.data.message,
        });
      } catch (e) {
        setApiErrorMessage({
          phone: ERROR_MESSAGES.API,
        });
      }
    });
  }

  function onSearch({ searchQuery, inTask }) {
    setApiErrorMessage({
      query: '',
    });

    axios({
      method: 'post',
      url: '/task/search',
      data: {
        cipher: searchQuery,
      },
    }).then((response) => {
      if (response.status === 200) {
        setTask({
          id: response.data.id,
          title: response.data.title,
          video: response.data.video,
          status: response.data.status,
          comment: response.data.comment,
          preview_text: response.data.preview_text,
        });

        localStorage.setItem('query', JSON.stringify(searchQuery));
        setQuery(searchQuery);

        if (!inTask) {
          history.push(TASK_LINK);
        }
      }
    }).catch((error) => {
      if (error.response.data && error.response.data.message) {
        setApiErrorMessage({
          query: error.response.data.message,
        });
      } else {
        setApiErrorMessage({
          query: ERROR_MESSAGES.API,
        });
      }
    });
  }

  function onLogout() {
    axios({
      method: 'get',
      url: '/user/logout',
    }).then((response) => {
      if (response.status === 200) {
        setLoggedIn(false);
        localStorage.setItem('loggedIn', JSON.stringify(false));

        history.push(SING_IN_LINK);
      }
    }).catch(() => {
      // console.log(error);
    });
  }

  function onRegisterStepPasswordCode({ passwordCode }) {
    axios({
      method: 'post',
      url: '/user/cipher/check',
      data: {
        cipher: passwordCode,
      },
    }).then((response) => {
      if (response.status === 200) {
        localStorage.setItem('user', JSON.stringify({
          passwordCode,
        }));
        setRegisterStep(2);
      }
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          if (error.response.data.message) {
            setApiErrorMessage({
              passwordCode: error.response.data.message,
            });
          }
        } else if (error.response.data.errors) {
          setApiErrorMessage({
            passwordCode: error.response.data.errors.cipher[0],
          });
        } else {
          setApiErrorMessage({
            passwordCode: ERROR_MESSAGES.API,
          });
        }
      }
    });
  }

  function onRegisterStepEmail({ email, password }) {
    const { passwordCode } = JSON.parse(localStorage.getItem('user')) || '';
    axios({
      method: 'post',
      url: '/user/register/email',
      data: {
        email,
        cipher: passwordCode,
      },
    }).then((response) => {
      if (response.status === 200) {
        localStorage.setItem('user', JSON.stringify({
          ...localStorage.getItem('user'),
          email,
          password,
          passwordCode,
          token: response.data.data.token,
        }));
        setRegisterStep(3);
      }
    }).catch((error) => {
      if (error.response.data && error.response.data.message) {
        setApiErrorMessage({
          email: error.response.data.message,
        });
      } else {
        setApiErrorMessage({
          email: ERROR_MESSAGES.API,
        });
      }
    });
  }

  function onRegisterStepPhoneCode({ phoneCode }) {
    const {
      email,
      password,
      passwordCode,
      token,
    } = JSON.parse(localStorage.getItem('user')) || {};

    axios({
      method: 'post',
      url: '/user/register/email/confirm',
      data: {
        email,
        password,
        token,
        code: phoneCode,
        cipher: passwordCode,

      },
    }).then(() => {
      localStorage.setItem('user', JSON.stringify({
        ...localStorage.getItem('user'),
        email,
        password,
        token,
        phoneCode,
        passwordCode,
      }));

      setRegisterStep(4);
    }).catch((error) => {
      if (error.response.data && error.response.data.errors) {
        setApiErrorMessage({
          phoneCode: error.response.data.errors.code[0],
        });
      } else {
        setApiErrorMessage({
          phoneCode: ERROR_MESSAGES.API,
        });
      }
    });
  }

  function onRegisterStepNames({ maleName, femaleName }) {
    axios({
      method: 'put',
      url: '/user',
      data: {
        name_boy: maleName,
        name_girl: femaleName,
      },
    }).then((response) => {
      if (response.status === 200) {
        setLoggedIn(true);
        localStorage.setItem('loggedIn', JSON.stringify(true));

        history.push(SEARCH_LINK);
      }
    }).catch((error) => {
      if (error.response.data && error.response.data.errors) {
        setApiErrorMessage({
          name_boy: error.response.data.errors.name_boy[0],
          name_girl: error.response.data.errors.name_girl[0],
        });
      } else {
        setApiErrorMessage({
          name_boy: ERROR_MESSAGES.API,
        });
      }
    });
  }

  function onLoginStepPhone({ phone }) {
    axios({
      method: 'post',
      credentials: 'include',
      url: '/user/login',
      data: {
        phone,
      },
    }).then((response) => {
      if (response.status === 200) {
        localStorage.setItem('userLogin', JSON.stringify({
          phone,
          token: response.data.data.token,
        }));

        setLoginStep(3);
      }
    }).catch((error) => {
      if (error.response.data && error.response.data.message) {
        setApiErrorMessage({
          phone: error.response.data.message,
        });
      } else {
        setApiErrorMessage({
          phone: ERROR_MESSAGES.API,
        });
      }
    });
  }

  function onLoginStepPhoneCode({ phoneCode }) {
    const {
      phone,
      token,
    } = JSON.parse(localStorage.getItem('userLogin')) || {};

    axios({
      method: 'post',
      credentials: 'include',
      url: '/user/login/confirm',
      data: {
        phone,
        token,
        code: phoneCode,

      },
    }).then((response) => {
      if (response.status === 200) {
        setLoggedIn(true);
        localStorage.setItem('loggedIn', JSON.stringify(true));

        history.push(SEARCH_LINK);
      }
    }).catch((error) => {
      if (error.response.data
        && error.response.data.errors
        && error.response.data.errors.code) {
        setApiErrorMessage({
          phoneCode: error.response.data.errors.code,
        });
      } else {
        setApiErrorMessage({
          phoneCode: ERROR_MESSAGES.API,
        });
      }
    });
  }

  function onLoginStepEmail({ email, password }) {
    axios({
      method: 'post',
      credentials: 'include',
      url: '/user/login/email',
      data: {
        email,
        password,
      },
    }).then((response) => {
      if (response.status === 200) {
        localStorage.setItem('userLogin', JSON.stringify({
          email,
          token: response.data.data.token,
        }));

        setLoggedIn(true);
        localStorage.setItem('loggedIn', JSON.stringify(true));

        history.push(SEARCH_LINK);
      }
    }).catch((error) => {
      if (error.response.data && error.response.data.message) {
        setApiErrorMessage({
          email: error.response.data.message,
        });
      } else {
        setApiErrorMessage({
          email: ERROR_MESSAGES.API,
        });
      }
    });
  }

  function onResetStepPassword({
    email,
    token,
    password,
    passwordConfirm,
  }) {
    axios({
      method: 'post',
      credentials: 'include',
      url: '/user/reset-password',
      data: {
        email,
        token,
        password,
        password_confirmation: passwordConfirm,
      },
    }).then((response) => {
      if (response.status === 200) {
        setResetStep(2);
        // history.push(SING_IN_LINK);
      }
    }).catch((error) => {
      if (error.response.data && error.response.data.message) {
        setApiErrorMessage({
          password: error.response.data.message,
        });
      } else {
        setApiErrorMessage({
          password: ERROR_MESSAGES.API,
        });
      }
    });
  }

  function onResetStepEmail({ email }) {
    axios({
      method: 'post',
      credentials: 'include',
      url: '/user/forgot-password',
      data: {
        email,
      },
    }).then((response) => {
      if (response.status === 200) {
        localStorage.setItem('userLogin', JSON.stringify({
          email,
        }));

        setForgotStep(2);
      }
    }).catch((error) => {
      if (error.response.data && error.response.data.message) {
        setApiErrorMessage({
          email: error.response.data.message,
        });
      } else {
        setApiErrorMessage({
          email: ERROR_MESSAGES.API,
        });
      }
    });
  }

  const handleSearch = (data) => {
    onSearch(data);
  };

  const handleLogout = () => {
    onLogout();
    setLoginStep(1);
    setRegisterStep(1);
  };

  const handleRegisterStepPasswordCode = (data) => {
    onRegisterStepPasswordCode(data);
  };

  const handleRegisterStepEmail = (data) => {
    onRegisterStepEmail(data);
  };

  const handleRegisterStepPhoneCode = (data) => {
    onRegisterStepPhoneCode(data);
  };

  const handleRegisterStepNames = (data) => {
    onRegisterStepNames(data);
  };

  const handleChangeTaskStatus = (data) => {
    onChangeTaskStatus(data);
  };

  const handleClearMessages = () => {
    setApiErrorMessage('');
  };

  const handleAddReview = (data) => {
    addReview(data);
  };

  const handleLoginStepPhone = (data) => {
    onLoginStepPhone(data);
  };

  const handleLoginStepPhoneCode = (data) => {
    onLoginStepPhoneCode(data);
  };

  const handleResetStepPassword = (data) => {
    onResetStepPassword(data);
  };

  const handleResetStepEmail = (data) => {
    onResetStepEmail(data);
  };

  const handleLoginStepEmail = (data) => {
    onLoginStepEmail(data);
  };

  const toggleAuthMetodPhone = () => {
    setLoginStep(2);
  };

  const toggleAuthMetodEmail = () => {
    setLoginStep(1);
  };

  return (
    <div
      className={`${locationUrl === TASK_LINK ? 'app__task' : 'app__all'}`}
    >
      <div className="app">
        <Logo
          link={SEARCH_LINK}
          name={settings.site_name}
          image={settings.logo}
          loggedIn={loggedIn}
          location={location}
        />
        <Switch>
          <Route exact strict path={SING_IN_LINK}>
            {!loggedIn && (
              <Auth title="Вход">
                <Nav variant="pills" className="price-v" defaultActiveKey="first">
                  <Nav.Item>
                    <Nav.Link eventKey="first" onClick={toggleAuthMetodEmail}>Email</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second" onClick={toggleAuthMetodPhone}>Телефон</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Login
                  registerLink={SING_UP_LINK}
                  loginStep={loginStep}
                  onLoginStepPhone={handleLoginStepPhone}
                  onLoginStepPhoneCode={handleLoginStepPhoneCode}
                  onLoginStepEmail={handleLoginStepEmail}
                  error={apiErrorMessage}
                  onClearMessages={handleClearMessages}
                  resetLink={FORGOT_PASS_LINK}
                />
              </Auth>
            )}
          </Route>
          <Route exact strict path={SING_UP_LINK}>
            {!loggedIn && (
              <Auth title="Регистрация">
                <Register
                  loginLink={SING_IN_LINK}
                  registerStep={registerStep}
                  onRegisterStepPasswordCode={handleRegisterStepPasswordCode}
                  onRegisterStepEmail={handleRegisterStepEmail}
                  onRegisterStepPhoneCode={handleRegisterStepPhoneCode}
                  onRegisterStepNames={handleRegisterStepNames}
                  error={apiErrorMessage}
                  onClearMessages={handleClearMessages}
                />
              </Auth>
            )}
          </Route>
          <Route exact strict path={FORGOT_PASS_LINK}>
            {!loggedIn && (
              <Auth title="Восстановить пароль">
                <ForgotPassword
                  registerLink={SING_UP_LINK}
                  loginLink={SING_IN_LINK}
                  forgotStep={forgotStep}
                  onResetStepEmail={handleResetStepEmail}
                  error={apiErrorMessage}
                  onClearMessages={handleClearMessages}
                />
              </Auth>
            )}
          </Route>
          <Route exact strict path={RESET_PASS_LINK}>
            {!loggedIn && (
              <Auth title="Восстановить пароль">
                <ResetPassword
                  registerLink={SING_UP_LINK}
                  loginLink={SING_IN_LINK}
                  resetStep={resetStep}
                  onResetStepPassword={handleResetStepPassword}
                  error={apiErrorMessage}
                  onClearMessages={handleClearMessages}
                />
              </Auth>
            )}
          </Route>
          <ProtectedRoute
            exact
            strict
            path={SEARCH_LINK}
            component={Search}
            settings={settings}
            loggedIn={loggedIn}
            error={apiErrorMessage}
            onClearMessages={handleClearMessages}
            redirectLink={SING_IN_LINK}
            onSearch={handleSearch}
          />
          <ProtectedRoute
            exact
            strict
            path={TASK_LINK}
            component={Task}
            loggedIn={loggedIn}
            questions={questions}
            ratings={ratings}
            error={apiErrorMessage}
            onClearMessages={handleClearMessages}
            onChangeTaskStatus={handleChangeTaskStatus}
            onAddReview={handleAddReview}
            onLogout={handleLogout}
            task={task}
            onSearch={handleSearch}
          />
          <Error404
            linkBack={SING_UP_LINK}
          />
        </Switch>
        <Footer
          messengers={settings.messengers}
        />
        {loggedIn && (
        <button
          type="button"
          onClick={handleLogout}
          className="app__logout"
        >
          Выйти
        </button>
        )}
      </div>
    </div>
  );
};

export default App;
