import './Review.css';
import React, { useState } from 'react';

import ReviewStar from './ReviewStar';

const Review = function Comment({
  commentSent,
  onSubmit,
  ratings,
  error,
}) {
  const [reviewRating, setReviewRating] = useState(-1);
  const [reviewHover, setReviewHover] = useState(0);
  const [reviewMessage, setReviewMessage] = useState('');

  const handleReviewRating = (e) => {
    setReviewRating(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    onSubmit({
      body: reviewMessage,
      rating: reviewRating,
    });
  };

  const handleReviewMessageChange = (e) => {
    setReviewMessage(e.target.value);
  };

  const onReviewHover = (newRating) => {
    setReviewHover(newRating);
  };

  if (!commentSent) {
    return (
      <form
        action="#"
        className="review"
        onSubmit={handleSubmit}
      >
        <div className="review__rating">
          <span className="review__title">Как вы оцениваете задание?</span>
          <div className="review__stars">
            {ratings
                        && ratings.map((object) => (
                          <ReviewStar
                            onChange={handleReviewRating}
                            obj={object}
                            id={object.id}
                            reviewRating={reviewRating}
                            reviewHover={reviewHover}
                            onReviewHover={onReviewHover}
                            key={object.id}
                          />
                        ))}
          </div>
        </div>
        <textarea
          placeholder="Напишите отзыв, обратную связь об этом задании"
          className={`review__textarea-text ${error.body || error.rating
            ? 'review__textarea-error' : ''}`}
          value={reviewMessage}
          onChange={handleReviewMessageChange}
        />
        {error.body
                && (
                <span
                  className="review__error"
                >
                  {error.body}
                </span>
                )}
        {error.rating
                && (
                <span
                  className="review__error"
                >
                  {error.rating}
                </span>
                )}
        <button type="submit" className="review__button-submit">
          Отправить отзыв
        </button>
      </form>
    );
  }
  return (
    <div className="review__result-text-ok">
      Спасибо за ваш отзыв!
    </div>
  );
};

export default Review;
