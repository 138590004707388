import './Error404.css';
import React from 'react';

const Error404 = function Error404() {
  return (
    <div>
      <p className="error404">404</p>
    </div>
  );
};

export default Error404;
