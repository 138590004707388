import './Input.css';
import './InputCode.css';
import React, { useState } from 'react';

import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';

const InputPassword = function InputPassword({
  title,
  value,
  onChange,
  error,
  errorMessages,
}) {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  return (
    <div className="input-box">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="input-box__title">
        {title}
        <input
          type={passwordShown ? 'text' : 'password'}
          className={`input-box__input ${error && error.password
            ? 'input-box__input-error' : ''}`}
          placeholder="минимум 8 символов"
          onChange={onChange}
          value={value}
          minLength="8"
        />
        <button
          className="input-password__icon-eye"
          type="button"
          onClick={togglePassword}
        >
          {!passwordShown
          && (
            <BsFillEyeFill />
          )}
          {passwordShown
          && (
            <BsFillEyeSlashFill />
          )}
        </button>
      </label>
      {error.password
      && (
        <span className="input-box__error">
          {error.password}
        </span>
      )}
      {errorMessages
      && (
        <span className="input-box__error">
          {errorMessages}
        </span>
      )}
    </div>
  );
};

export default InputPassword;
