import './Button.css';
import React from 'react';

const Button = function Button({ name }) {
  return (
    <button type="submit" className="button">{name}</button>
  );
};

export default Button;
