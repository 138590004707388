import './FaqQuestionsList.css';
import React from 'react';

import FaqQuestionsCard from './FaqQuestionsCard';

const FaqQuestionsList = function FaqQuestionsList({ questions }) {
  return (
    <ul className="faq-question-list">
      {questions && questions.map((item) => (
        <FaqQuestionsCard
          key={`${item.question}${item.answer}`}
          question={item.question}
          answer={item.answer}
        />
      ))}
    </ul>
  );
};

export default FaqQuestionsList;
