import './Input.css';
import React from 'react';

const Input = function Input({
  title,
  value,
  onChange,
  error,
  description,
}) {
  return (
    <div className="input-box">
      <label htmlFor="inputId" className="input-box__title">
        {title}
        <input
          id="inputId"
          type="text"
          className={`input-box__input ${error ? 'input-box__input-error' : ''}`}
          placeholder=""
          onChange={onChange}
          value={value}
        />
      </label>
      {description
            && <span className="input-box__input-description">{description}</span>}
      {error
            && (
            <span
              className="input-box__error"
            >
              {error}
            </span>
            )}
    </div>
  );
};

export default Input;
