import './Search.css';

import React, { useEffect, useState } from 'react';

import SVG from 'react-inlinesvg';

import backgroundSearchIllustration from '../images/backgroundSearchIllustration.svg';
import backgroundSearchLine from '../images/backgroundSearchLine.svg';
import backgroundSearchLineTwo from '../images/backgroundSearchLineTwo.svg';
import ResponsivePlayer from './ResponsivePlayer';
import SearchForm from './SearchForm';

const Search = function Search({
  onSearch,
  error,
  settings,
  // onClearMessages,
}) {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    if (!searchQuery) {
      return;
    }

    onSearch({
      searchQuery,
    });
  };

  useEffect(() => {
    if (error.query) {
      // setSearchQuery('');
    }
  }, [error.query]);

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
    // onClearMessages();
  };

  return (
    <section className="search">
      <h1 className="search__title">
        Добро пожаловать в игру
        {'\n'}
        «Терапия»!
      </h1>
      <div className="search__description">
        На вашем постере, под защитным слоем, расположены шифры.
        {'\n'}
        Введите шифр, чтобы посмотреть видео этого задания
      </div>
      <SearchForm
        onSubmit={handleSearchSubmit}
        onChange={handleChange}
        value={searchQuery}
        error={error}
      />
      <section className="search__player">
        <h1 className="search__player-title">
          Важно! Ознакомьтесь с инструкцией к игре
        </h1>
        <ResponsivePlayer
          url={settings.video_start}
        />
      </section>
      <SVG
        src={backgroundSearchLine}
        className="app__background-vector"
        uniquifyIDs
      />
      <SVG
        src={backgroundSearchLineTwo}
        className="app__background-vector-two"
        uniquifyIDs
      />
      <SVG
        src={backgroundSearchIllustration}
        className="app__background-illustration"
        uniquifyIDs
      />
    </section>
  );
};

export default Search;
