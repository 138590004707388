import './Register.css';

import React, { useCallback, useState } from 'react';

import { Link } from 'react-router-dom';

import Button from './Button';
import Fieldset from './Fieldset';
import Form from './Form';
import Input from './Input';
import InputCode from './InputCode';
import InputEmail from './InputEmail';
import InputPassword from './InputPassword';

const Register = function Register({
  loginLink,
  error,
  onRegisterStepPasswordCode,
  onRegisterStepEmail,
  onRegisterStepPhoneCode,
  onRegisterStepNames,
  registerStep,
}) {
  const [passwordCode, setPasswordCode] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [maleName, setMaleName] = useState('');
  const [femaleName, setFemaleName] = useState('');

  const handleStepPasswordCodeSubmit = (e) => {
    e.preventDefault();

    onRegisterStepPasswordCode({
      passwordCode,
    });
  };

  const handleStepEmailSubmit = (e) => {
    e.preventDefault();

    onRegisterStepEmail({
      email,
      password,
    });
  };

  const handleRepeatCodeSubmit = () => {
    onRegisterStepEmail({
      email,
      password,
    });
  };

  const handleStepPhoneCodeSubmit = (e) => {
    e.preventDefault();

    onRegisterStepPhoneCode({
      phoneCode,
    });
  };

  const handleStepNamesSubmit = (e) => {
    e.preventDefault();

    onRegisterStepNames({
      maleName,
      femaleName,
    });
  };

  const handlePasswordCodeChange = (e) => {
    setPasswordCode(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePhoneCodeChange = (e) => {
    setPhoneCode(e.target.value);
  };

  const handleMaleNameChange = (e) => {
    setMaleName(e.target.value);
  };

  const handleFemaleNameChange = (e) => {
    setFemaleName(e.target.value);
  };

  const LoginBoxComponent = useCallback(() => (
    <div className="signup__link-box">
      <span
        className="signup__link-box-title"
      >
        Я существующий пользователь
      </span>
      <Link
        to={loginLink}
        className="signup__link-signin"
      >
        Войти
      </Link>
    </div>
  ), [loginLink]);

  return (
    <>
      {registerStep === 1
        && (
          <Form onSubmit={handleStepPasswordCodeSubmit}>
            <Input
              title="Введите ключ:"
              onChange={handlePasswordCodeChange}
              value={passwordCode}
              error={error.passwordCode}
              description="Введите 10 символов (на обороте ключ карты)"
            />
            <Button name="Продолжить" />
            <LoginBoxComponent />
          </Form>
        )}
      {registerStep === 2
        && (
          <Form onSubmit={handleStepEmailSubmit}>
            <Fieldset>
              <InputEmail
                title="Введите email:"
                value={email}
                onChange={handleEmailChange}
                error={error}
                description="На эту почту вы получите кодом подтверждения"
              />
              <InputPassword
                title="Введите пароль:"
                value={password}
                onChange={handlePasswordChange}
                error={error}
                description=""
              />
            </Fieldset>
            <Button name="Дальше" />
            <LoginBoxComponent />
          </Form>
        )}
      {registerStep === 3
        && (
          <Form onSubmit={handleStepPhoneCodeSubmit}>
            <InputCode
              onChange={handlePhoneCodeChange}
              onRepeatCode={handleRepeatCodeSubmit}
              value={phoneCode}
              error={error}
            />
            <Button name="Подтвердить" />
          </Form>
        )}
      {registerStep === 4
        && (
          <Form onSubmit={handleStepNamesSubmit}>
            <Fieldset>
              <Input
                title="Введите имя мужчины:"
                onChange={handleMaleNameChange}
                phone={passwordCode}
                error={error.name_boy}
              />
              <Input
                title="Введите имя женщины:"
                onChange={handleFemaleNameChange}
                phone={passwordCode}
                error={error.name_girl}
              />
            </Fieldset>
            <Button name="Сохранить" />
          </Form>
        )}
    </>
  );
};
export default Register;
