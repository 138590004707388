import './Form.css';

import React from 'react';

const Form = function Form({
  children,
  onSubmit,
}) {
  return (
    <form
      action="#"
      onSubmit={onSubmit}
      className="form"
    >
      {children}
    </form>
  );
};

export default Form;
