import './ReviewStar.css';
import React from 'react';

import { ReactComponent as IconStar } from '../images/Star.svg';

const ReviewStar = function CommentStar({
  id,
  onChange,
  reviewRating,
}) {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <>
      <input
        type="radio"
        id={`radio-${id}`}
        name="radio"
        className="review-star__radio"
        value={id}
        onClick={handleChange}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor={`radio-${id}`}>
        <IconStar className={`review-star__image
        ${reviewRating >= id && 'review-star__image-active'}`}
        />
      </label>
    </>
  );
};

export default ReviewStar;
