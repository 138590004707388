import React from 'react';

import { Redirect, Route } from 'react-router-dom';

const ProtectedRoute = function ProtectedRoute({
  component: Component,
  loggedIn,
  redirectLink,
  ...props
}) {
  return (
    <Route>
      {/* eslint-disable-next-line react/jsx-props-no-spreading,no-self-compare */}
      {loggedIn ? <Component {...props} />
        : <Redirect to={redirectLink} from="/" />}
    </Route>
  );
};

export default ProtectedRoute;
