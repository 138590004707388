import './Input.css';
import './InputCode.css';
import React, { useEffect, useState } from 'react';

import { timeRepeatCode } from '../utils/config';

const InputCode = function InputCode({
  value,
  onChange,
  onRepeatCode,
  error,
}) {
  const [currentCount, setCount] = useState(timeRepeatCode);

  useEffect(() => {
    if (currentCount <= 0) {
      return;
    }
    const id = setInterval(() => setCount(currentCount - 1), 1000);
    // eslint-disable-next-line consistent-return
    return () => clearInterval(id);
  }, [currentCount]);

  return (
    <div className="input-box">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="input-box__title">
        Введите код:
        <input
          type="text"
          className={`input-box__input ${error && error.phoneCode
            ? 'input-box__input-error' : ''}`}
          placeholder=""
          onChange={onChange}
          value={value}
        />
      </label>
      {currentCount > 0
        ? (
          <span className="input-box__input-description">
            Не получили код? Проверьте папку Спам или отправьте код повторно через&nbsp;
            <span
              className="input-code__input-description-timer"
            >
              {currentCount}
              {' '}
              секунд
            </span>
          </span>
        )
        : (
          <button
            type="submit"
            className="input-code__link-send-code"
            onClick={(e) => {
              onRepeatCode(e);
              setCount(timeRepeatCode);
            }}
          >
            Отправить код повторно
          </button>
        )}
      {error.phoneCode
      && (
        <span className="input-box__error">
          {error.phoneCode}
        </span>
      )}
    </div>
  );
};

export default InputCode;
