import React from 'react';

import './Auth.css';
import SVG from 'react-inlinesvg';

import Background from '../images/backgroundIllustration.svg';
import Line1 from '../images/backgroundLine.svg';
import Line2 from '../images/backgroundLineTwo.svg';

const Auth = function Auth({
  children,
  title,
}) {
  return (
    <main className="auth">
      <h1 className="auth__title">{title}</h1>
      {children}
      <SVG
        src={Background}
        className="app__background-image"
        uniquifyIDs
      />
      <SVG
        src={Line1}
        className="app__background-line"
        uniquifyIDs
      />
      <SVG
        src={Line2}
        className="app__background-line-two"
        uniquifyIDs
      />
    </main>
  );
};

export default Auth;
